<template>
    <div>
        <div id="spec" class="text-2xl lg:text-4xl font-bold text-center w-full pt-12 lg:pt-20 pb-12">
            Nasi specjalisci
        </div>
        <div class="container mx-auto lg:px-24 lg:pb-8">
            <table class="text-xs border-collapse table-auto w-full px-2">
                <thead>
                <tr class="bg-gray-200">
                    <th class="lg:text-xl text-left p-3">Specjalizacja</th>
                    <th class="lg:text-xl text-left p-3">Lekarz</th>
                    <th class="lg:text-xl text-left p-3">Usługi</th>
                </tr>
                </thead>
                <tbody>

                <template v-for="(spec, all_index) in doctorsList">
                    <tr :class="(index === spec.staffs.length -1) && 'border-b-2'"
                        v-for="(doctor, index) in spec.staffs" :key="all_index+'-'+index">
                        <td v-if="index == 0" class="p-3 lg:text-xl uppercase font-bold align-top text-left">
                            {{ spec.specialization }}
                        </td>
                        <td v-else class="p-3 lg:text-xl uppercase font-bold align-top text-left"></td>
                        <td class="p-3 lg:text-xl font-bold text-left">
                            <a :href="'/staff/' + doctor.id" class="no-underline hover:underline">{{ doctor.person }}</a>
                        </td>
                        <td class="p-3 lg:text-xl text-left" v-html="doctor.services"></td>
                    </tr>
                </template>

                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: "DoctorList",
        data() {
            return {
                doctorsList: [],
                specializations: [],
                response: []
            }
        },
        mounted() {
            axios.get('/api/v1/doctors-list').then(response => {
                this.doctorsList = response.data;
            })
        }
    }
</script>

<style scoped>

</style>
