import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        meta: {
            title: "CM Diagnoza"
        },
        name: "Home",
        component: Home,
    },
    {
        path: "/staff/:id",
        meta: {
            title: "CM Diagnoza"
        },
        name: "Staff",
        component: () =>
            import(/* wepackChunkName: "staff" */ "../views/Staff.vue"),
    },
    {
        path: "/service/:id",
        meta: {
            title: "CM Diagnoza"
        },
        name: "Service",
        component: () =>
            import(/* wepackChunkName: "staff" */ "../views/Service.vue"),
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
