<template>
    <div class="relative m-0 p-0 w-full">
        <!-- odkomentuj img aby porównać do oryginału -->
        <!-- <img class="absolute top-0 right-0 w-full opacity-20" src="images/diagnoza_propo_v2.jpeg"></img> -->
        <Header/>
        <!-- info section -->
        <div class="container mx-auto mt-4 lg:mt-14 lg:mb-10 lg:px-24 lg:h-82">
            <div class="flex flex-col lg:flex-row justify-between">
                <div class="lg:w-1/2 lg:pr-12">
                    <img class="w-full lg:w-auto lg:rounded-3xl" src="../assets/images/usg1.jpg" alt="no image" rel="preload">

                </div>
                <div class="lg:w-1/2 py-6 px-3 font-bold italic text-xs lg:text-xl lg:leading-7 text-left">
                    <p>Centrum Medyczne DIAGNOZA działa od 2006r. Gabinety lekarskie mieszczą się w Krakowie przy ul.
                        Zaczarowane Koło 5, zostały zaprojektowane i wyposażone
                        tak, by reprezentować najwyższy standard.</p>
                    <p>Do współpracy zaprosiliśmy lekarzy o najlepszych kwalifikacjach.</p><br>
                    <p>Serdecznie zapraszamy do naszych gabinetów.</p><br>
                    <p class="not-italic">Anna Błażewska-Gruszczyk<br/>Marian Słowiaczek</p>
                </div>
            </div>
        </div>
        <!-- info section -->
        <!-- offer section -->
        <div id="offer" class="bg-gray-200 w-full lg:mt-12">
            <div class="container mx-auto px-3 lg:px-24 lg:pb-8">
                <div class="lg:flex-row flex flex-col justify-between align-center pt-4 lg:pt-10">
                    <div class="lg:w-1/2 pr-3 lg:pr-12">
                        <p class="font-bold italic lg:text-xl lg:leading-7 pb-4 lg:pb-8 text-left">W naszej ofercie znajdą Państwo
                            szeroki
                            zakres usług m.in. :</p>
                        <ul v-for="(service, index) in services" :key="index"
                            class="services pl-6 text-sm lg:text-xl italic lg:leading-8 list-outside list-disc">
                            <li class="text-green-800">
                                <div class="text-gray-800 text-left">
                                    <a :href="'/service/' + service.id"> {{ service.name }} </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="lg:w-1/2">
                        <div class="grid pt-6 lg:pt-0 gap-2 grid-flow-col grid-cols-2 grid-rows-2 lg:gap-4 lg:pl-8 h-auto">
                            <img class="w-auto lg:rounded-3xl " src="../assets/images/gin1.jpg" alt="no image" rel="preload">
                            <img class="w-auto lg:rounded-3xl" src="../assets/images/viofor.jpg" alt="no image" rel="preload">
                            <img class="w-auto lg:rounded-3xl" src="../assets/images/zab1.jpg" alt="no image" rel="preload">
                            <img class="w-auto lg:rounded-3xl " src="../assets/images/reg1.jpg" alt="no image" rel="preload">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- offer section -->
        <DoctorList/>
        <!-- contact section -->
        <Contact/>
        <!-- contact section -->
        <Footer/>
    </div>
</template>

<script>
    import axios from "axios";
    import Header from "../components/Header";
    import Contact from "../components/Contact";
    import Footer from "../components/Footer";
    import DoctorList from "../components/DoctorList";

    export default {
        name: "Home",
        components: {
            DoctorList,
            Footer,
            Contact,
            Header
        },
        data() {
            return {
                services: []
            }
        },
        beforeRouteEnter(to, from, next) {
            axios.get('https://www.usgdiagnoza.pl/api/v1/services').then(response => {
                next(vm => vm.setData(response.data))
            })
        },
        methods: {
            setData (services) {
                this.services = services
            }
        }
    };
</script>
