<template>
    <div>
    <footer class="w-full lg:h-36 lg:pt-6 font-bold text-lg bg-gray-700 text-white text-left">
        <vue-cookie-accept-decline
            class="text-gray-800"
            :ref="'myPanel1'"
            :elementId="'myPanel1'"
            :debug="false"
            :position="'bottom'"
            :type="'floating'"
            :disableDecline="true"
            :transitionName="'slideFromBottom'"
            @status="cookieStatus"
            @clickedAccept="cookieClickedAccept">

            <!-- Optional -->
            <div slot="message">
                <div class="flex flex-row">
                    <img src="../assets/images/lock.png" class="h-16">
                    <div class="text-gray-800 ml-4">
                        <p class="text-xs font-medium">Informacje dotyczące przetwarzania danych medycznych</p>
                        <a href="/OgolnyObowiazekInformacyjny.pdf" target="_blank" class="text-xs font-hairline py-1">Więcej szczegółów</a>
                    </div>
                </div>
            </div>

            <!-- Optional -->
            <div slot="acceptContent">
                <div>
                    Rozumiem
                </div>
            </div>
        </vue-cookie-accept-decline>

        <div class="container mx-auto lg:px-24">
            <div class="p-4 lg:p-0 flex lg:inline-flex justify-between">
                <a class="lg:pr-3 lg:mr-3 lg:border-r-2 hover:text-yellow-500 font-normal">Polityka przetwarzania danych medycznych</a>
                <a href="/OgolnyObowiazekInformacyjny.pdf" target="_blank" class="hover:text-yellow-500 font-normal">© Centrum Medyczne Diagnoza</a>
            </div>
        </div>
    </footer></div>
</template>

<script>
    import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css'

    import VueCookieAcceptDecline from 'vue-cookie-accept-decline'

    export default {
        name: "Footer",
        comments: { VueCookieAcceptDecline },
        data () {
            return {
                status: null
            }
        },
        methods: {
            cookieStatus (status) {
                console.log('status: ' + status)
                this.status = status
            },
            cookieClickedAccept () {
                console.log('here in accept')
                this.status = 'accept'
            },
            // cookieRemovedCookie () {
            //     console.log('here in cookieRemoved')
            //     this.status = null
            //     this.$refs.myPanel1.init()
            // },

            // removeCookie () {
            //     console.log('Cookie removed')
            //     this.$refs.myPanel1.removeCookie()
            // }
        },
        computed: {
            // statusText () {
            //     return this.status || 'No cookie set'
            // }
        }
    }
</script>

<style scoped>

</style>
