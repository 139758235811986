import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import './assets/css/main.scss';
import VuePageTitle from 'vue-page-title'
import "vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css";
import VueCookieAcceptDecline from "vue-cookie-accept-decline";

Vue.use(VuePageTitle, { router })
Vue.component("vue-cookie-accept-decline", VueCookieAcceptDecline);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
