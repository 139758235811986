<template>
    <header>
        <!-- navbar-logo -->
        <ContactWidget/>
        <div class="container mx-auto lg:-mt-9">
            <div class="flex justify-center">
                <img class="w-48 lg:w-72" src="../assets/images/logo.png" rel="preload">
            </div>
        </div>
        <!-- navbar -->
        <!-- navigation -->
        <div
            class="mx-auto items-center  pt-4 text-xl lg:text-2xl w-full flex-col lg:flex-row flex justify-center lg:border-b-2 lg:pt-10 lg:pb-6">
            <a href="/" class="py-2 lg:py-0 font-bold lg:px-6 hover:bg-gray-100 rounded-full">Strona główna</a>
            <a href="#offer" class="py-2 lg:py-0 font-bold lg:px-6 hover:bg-gray-100 rounded-full">Usługi</a>
            <a href="#spec" class="py-2 lg:py-0 font-bold lg:px-6 hover:bg-gray-100 rounded-full">Specjaliści</a>
            <a href="#contact" class="py-2 lg:py-0 font-bold lg:px-6 hover:bg-gray-100 rounded-full">Kontakt</a>
        </div>
        <!-- navigation -->
    </header>
</template>

<script>
    import ContactWidget from "./ContactWidget";
    export default {
        name: "Header",
        components: {ContactWidget}
    }
</script>

<style scoped>

</style>
