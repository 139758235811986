<template>
    <div>
        <div class="container mx-auto lg:px-24 pb-8">
            <div class="text-2xl lg:text-4xl font-bold text-center w-full pt-6 lg:pt-16 lg:pb-12">
                Kontakt
            </div>
            <div class="lg:hidden px-3 ">
                <div class="font-bold pt-5">Centrum Medyczne DIAGNOZA</div>
                <div class="font-bold ">Anna Błażewska-Gruszczyk, Marian Słowiaczek SJ</div>
                <ul class="pt-5 pb-2 none">
                    <li> Adres: ul. Zaczarowane Koło 5, 30-087 Kraków</li>
                    <li>NIP: 6772259830</li>
                    <li>REGON: 120141510</li>
                    <li><a href="tel:12 638 04 73">Telefon: 12 638 04 73</a></li>
                    <li><a href="tel:12 353 08 45">Fax: 12 353 08 45</a></li>
                </ul>
            </div>
            <div class="flex justify-between mt-6 lg:flex-row flex-col w-full">
                <div class="lg:w-1/2 lg:pr-12 w-full h-auto lg:px-3 lg:pl-0">
                    <iframe id="map" class="w-full h-96 lg:h-full px-0"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2560.4164228064456!2d19.901843315717933!3d50.07848987942568!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47165bb5e16256ef%3A0x94719bcf2ad15515!2sCentrum%20Medyczne%20DIAGNOZA!5e0!3m2!1sen!2spl!4v1627679887141!5m2!1sen!2spl"
                            allowfullscreen="true" loading="lazy"></iframe>
                </div>
                <div id="contact" class="lg:w-1/2 w-full px-3 lg:px-0 text-center lg:text-left">
                    <div class="lg:font-bold text-2xl leading-3 mt-8 lg:mt-0">Rejestracja</div>
                    <div class="pt-12 lg:text-xl">Od poniedziałku do piątku w godz. <span
                        class="font-bold">8.00-19.00</span>
                    </div>
                    <div class="lg:text-xl">Pobieranie krwi do badań od poniedziałku do piątku w godz. <span
                        class="font-bold">7.30-16.00</span></div>
                    <div class="lg:text-xl">Telefon <span class="font-bold"><a href="tel:12 638 04 73">12 638 04
                            73</a></span>
                    </div>
                    <div class="lg:text-xl">Telefon <span class="font-bold"><a
                        href="tel:660 446 574">660 446 574</a>, <a
                        href="tel:794 500 905">794 500 905</a></span></div>
                    <div class="pt-10 lg:text-xl italic border-b-2 pb-2">Bardzo prosimy o ograniczenie liczby osób
                        towarzyszących do niezbędnego minimum
                    </div>
                    <div class="hidden lg:block">
                        <div class="font-bold lg:text-2xl pt-5">Centrum Medyczne DIAGNOZA</div>
                        <div class="font-bold lg:text-2xl">Anna Błażewska-Gruszczyk, Marian Słowiaczek SJ</div>
                        <ul class="pt-10 lg:text-xl pb-2 none">
                            <li> Adres: ul. Zaczarowane Koło 5, 30-087 Kraków</li>
                            <li>NIP: 6772259830</li>
                            <li>REGON: 120141510</li>
                            <li><a href="tel:12 638 04 73">Telefon: 12 638 04 73</a></li>
                            <li><a href="tel:12 353 08 45">Fax: 12 353 08 45</a></li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Contact"
    }
</script>

<style scoped>

</style>
