<template>
    <div class="lg:mr-72 p-6 lg:pr-0">

        <div class="flex flex-col">
            <div class="flex flex-row justify-end">
                <div
                    class="mx-2 lg:mx-12 w-6 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
                    <a href="#map">
                        <svg aria-hidden="false" focusable="true" data-prefix="fas" data-icon="map-marker-alt"
                             class="svg-inline--fa fa-map-marker-alt fa-w-12" role="img"
                             xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 384 512">
                            <path fill="currentColor"
                                  d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z">
                            </path>
                        </svg>
                    </a>
                </div>
                <div
                    class="w-8 text-green-500 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
                    <a href="tel:+48126380473">
                        <svg aria-hidden="false" focusable="true" data-prefix="fas" data-icon="phone-alt"
                             class="svg-inline--fa fa-phone-alt fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 512 512">
                            <path fill="currentColor"
                                  d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z">
                            </path>
                        </svg>
                    </a>
                </div>
            </div>
            <div>
                <div class="flex flex-row justify-end">
                    <div class="text-2xl font-medium mt-5 bg-gray-200 py-1 px-3 rounded-full"><a
                        href="tel:+48126380473">12 638 04 73</a></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ContactWidget"
    }
</script>

<style scoped>

</style>
